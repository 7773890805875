import SYLLABUS_TEMPLATE from '@views/syllabus/template/syllabusTemplte.json'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (length, currentCampusScope) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: SYLLABUS_TEMPLATE.text,
          value: length || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: !currentCampusScope
            ? SYLLABUS_TEMPLATE.tooltipDescriptionInstitute
            : SYLLABUS_TEMPLATE.tooltipDescriptionCampus,
        },
      ],
    },
  ]
  return stats
}
export const templateStats = (count, currentCampusScope) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: SYLLABUS_TEMPLATE.templateText,
          value: count,
          tooltipDescription: !currentCampusScope
            ? SYLLABUS_TEMPLATE.templateInstitute
            : SYLLABUS_TEMPLATE.templateCampus,
        },
      ],
    },
  ]
  return stats
}
